@import 'variables';

.app-header {
  justify-self: flex-start;
}

.logo {
  padding-top: 0.5em;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 3em;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  .stam {
    font-family: 'Montserrat', sans-serif;
    color: $primary-color;
  }

  .pot {
    font-family: 'Orbitron', sans-serif;
    color: $accent-color;
  }
}
