@import 'variables';

.info-card {
  &__container {
    display: grid;
    grid-template-rows: 15px 31px 15px;
    grid-column-gap: 5px;
    padding: 7px;
    font-size: 12px;
    overflow-y: hidden;
    text-align: center;
    //transition: grid 0.3s ease-out;
  }

  &__first {
    font-size: 14px;
    font-weight: bold;
    line-height: 15px;
  }

  &__second {
    color: $primary-color;
    font-size: 24px;
    line-height: 31px;
  }

  &__third {
    font-size: 12px;
    line-height: 15px;
  }
}
