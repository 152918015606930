@import 'variables';

.button {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-family: $header-font;
  font-size: $base-font-size;
  background: $accent-color;
  color: white;
  border: none;
  padding: 0 10px;
  height: 40px;
  min-width: 84px;
  transition: 0.3s;
  line-height: 40px;
  text-transform: uppercase;
  outline: none;
  text-decoration: none;
  border-radius: 5px;

  &:not(:disabled):hover {
    background: $accent-color-light;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background: $accent-color-dark;
    transition: 0s;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    opacity: 0.5;
  }

  & svg {
    vertical-align: middle;
  }

  //&.success {
  //  background: $second-house-color;
  //
  //  &:not(:disabled):hover {
  //    background: $second-house-color-light;
  //  }
  //
  //  &:active {
  //    background: $second-house-color-dark;
  //  }
  //}
}
