@import 'variables';

.input,
.textarea {
  border: 0;
  padding: 0 10px;
  border-top: 2px solid $body-bg;
  background-color: $body-bg;
  font-size: $base-font-size;
  font-family: $base-font;
  width: 100%;
  min-height: 40px;
  border-radius: 5px;

  &:focus {
    outline: none;
  }

  &:invalid {
    box-shadow: none;

    & + .input__underline {
      background: linear-gradient(to right, rgba(0, 0, 0, 0), $primary-color, rgba(0, 0, 0, 0));
    }
  }

  &.dirty + .input__underline,
  &:focus + .input__underline {
    transform: scaleX(1);
  }
}

.input {
  height: 35px;
  line-height: 35px;

  &__wrapper {
    background-color: $body-bg;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    border-radius: 5px;
  }

  &__underline {
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), $accent-color, rgba(0, 0, 0, 0));
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 250ms ease;
    bottom: 0;
    position: absolute;
  }
}

.textarea {
  height: calc(100% - 2px);
  resize: none;
  line-height: $base-line-height;
}
