// Body
$body-bg: #f8fafc;

// Typography
$header-font: 'Montserrat', sans-serif;
$base-font: 'Poppins', sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.6;

// Colors
$primary-font-color: rgba(0, 0, 0, 0.78);
$primary-color: #800080;
$accent-color: #08605f;
$accent-color-dark: #045352;
$accent-color-light: #0c6c6b;
$background-color: #93a8ac;
$background-gradient: linear-gradient(to bottom, #1a1a29, #4e4e59, #0f0f1e);
